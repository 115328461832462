import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers"; // Ensure correct imports
import { ethers } from "ethers"; // Ensure ethers is imported

const opts = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            name: "galadriel",
            rpc: {
                696969: "https://devnet.galadriel.com/" // RPC URL for Galadriel network
            },
            chainId: 696969,
            cacheProvider: false
        }
    }
};

export const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions: opts, // required
    network: "galadriel"
});

export function getWeb3Provider() {
    return new JsonRpcProvider("https://devnet.galadriel.com/");
}

export async function getWeb3Signer() {
    const web3Provider = await web3Modal.connect();
    const provider = new Web3Provider(web3Provider);

    const network = await provider.getNetwork();
    if (network.chainId !== 696969) {
        throw new Error("Invalid network. Only Galadriel network is supported");
    }

    return provider;
}
