import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Phaser from 'phaser';
import GridEngine from 'grid-engine';
import BootScene from './game/scenes/BootScene';
import MainMenuScene from './game/scenes/MainMenuScene';
import GameOverScene from './game/scenes/GameOverScene';
import GameScene from './game/scenes/GameScene';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {Backdrop, Fade, Modal, Typography} from '@material-ui/core';
import dialogBorderBox from './game/assets/images/dialog_borderbox.png';
import GameMenu from "./game/GameMenu";
import DialogBox from "./game/DialogBox";
import HeroCoin from "./game/HeroCoin";
import HeroHealth from "./game/HeroHealth";
import './App.css';
import {calculateGameSize} from "./game/utils";

const {width, height, multiplier} = calculateGameSize();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
  postContainer: {
    maxWidth: '90%',
    maxHeight: '90%',
  },
  gameContentWrapper: {
    width: `${width * multiplier}px`,
    height: `${height * multiplier}px`,
    margin: 'auto',
    padding: 0,
    overflow: 'hidden',
    '& canvas': {
      imageRendering: 'pixelated',
      '-ms-interpolation-mode': 'nearest-neighbor',
      boxShadow: '0px 0px 0px 3px rgba(0,0,0,0.75)',
    },
  },
  pageWrapper: {
    background: theme.palette.background.paper,
    padding: 0,
    margin: 0,
  },
  loadingText: {
    fontFamily: '"Press Start 2P"',
    marginTop: '30px',
    marginLeft: '30px',
  },
  preLoadDialogImage: {
    backgroundImage: `url("${dialogBorderBox}")`,
    backgroundSize: '1px',
    backgroundRepeat: 'no-repeat',
  },
  gameWrapper: {
    color: '#FFFFFF',
  },
  gameGif: {
    width: '100%',
    position: 'absolute',
    imageRendering: 'pixelated',
    top: 0,
  },
}));

const promptIds = {
  "Galadriel": 0,
  "Legolas": 1,
  "Gandalf": 2,
  "Celeborn": 3,
}

const npcNames = {
  "npc_01": "Galadriel",
  "npc_02": "Legolas",
  "npc_03": "Celeborn",
  "npc_04": "Gandalf",
}

const dialogs = {
  "npc_01": [
    {"message": "Welcome, Frodo of the Middle Earth. One who has seen the Eye of the enemy is not unchanged."},
    {"message": "May you find the courage you seek, reflected in the mirror of your own heart."}
  ],
  "npc_02": [
    {"message": "I have not seen your kind before, Frodo. Yet, I sense a great destiny before you."},
    {"message": "Keep your eyes open and your feet swift. Danger is never far in these lands."}
  ],
  "npc_03": [
    {"message": "Your coming to us is as the footsteps of doom. You bring great evil here, Ring-bearer."},
    {"message": "Yet hope remains while the Company is true. Do not let your hearts be troubled."}
  ],
  "npc_04": [
    {"message": "Ah, Frodo, my dear hobbit, much depends on your courage and little on your strength."},
    {"message": "Beware! The enemy has many spies in his service. Not all things are what they seem."}
  ],
  "sword": [{
    "message": "You got a sword",
  }],
  "push": [{
    "message": "You can push boxes now",
  }],
  "sign_01": [{
    "message": "You can read this!",
  }],
  "book_01": [{
    "message": "Welcome to the game!!!",
  }]
};

function App() {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [characterName, setCharacterName] = useState('');
  const [characterIndex, setCharacterIndex] = useState(-1);
  const [gameMenuItems, setGameMenuItems] = useState([]);
  const [gameMenuPosition, setGameMenuPosition] = useState('center');
  const [heroHealthStates, setHeroHealthStates] = useState([]);
  const [heroCoins, setHeroCoins] = useState(null);

  const handleMessageIsDone = useCallback(() => {
    const customEvent = new CustomEvent(`${characterName}-dialog-finished`, {
      detail: {},
    });
    window.dispatchEvent(customEvent);

    setMessages([]);
    setCharacterName('');
    setCharacterIndex(-1);
  }, [characterName]);

  const handleMenuItemSelected = useCallback((selectedItem) => {
    if (selectedItem === "faucet") {
      window.open("https://docs.galadriel.com/faucet", '_blank').focus();
      return
    }
    setGameMenuItems([]);

    const customEvent = new CustomEvent('menu-item-selected', {
      detail: {
        selectedItem,
      },
    });
    window.dispatchEvent(customEvent);
  }, []);

  useEffect(() => {
    const game = new Phaser.Game({
      type: Phaser.AUTO,
      title: 'some-game-title',
      parent: 'game-content',
      orientation: Phaser.Scale.LANDSCAPE,
      localStorageName: 'some-game-title',
      width,
      height,
      autoRound: true,
      pixelArt: true,
      scale: {
        autoCenter: Phaser.Scale.CENTER_BOTH,
        mode: Phaser.Scale.ENVELOP,
      },
      scene: [
        BootScene,
        MainMenuScene,
        GameScene,
        GameOverScene,
      ],
      physics: {
        default: 'arcade',
      },
      plugins: {
        scene: [
          {
            key: 'gridEngine',
            plugin: GridEngine,
            mapping: 'gridEngine',
          },
        ],
      },
      backgroundColor: '#000',
    });

    // window.phaserGame = game;
  }, []);

  useEffect(() => {
    const dialogBoxEventListener = ({detail}) => {
      // TODO fallback
      const promptId = promptIds[npcNames[detail.characterName] || characterName]
      setCharacterIndex(promptId);
      setCharacterName(detail.characterName);

      setMessages(
        dialogs[detail.characterName]
      );
    };
    window.addEventListener('new-dialog', dialogBoxEventListener);

    const gameMenuEventListener = ({detail}) => {
      setGameMenuItems(detail.menuItems);
      setGameMenuPosition(detail.menuPosition);
    };
    window.addEventListener('menu-items', gameMenuEventListener);

    const heroHealthEventListener = ({detail}) => {
      setHeroHealthStates(detail.healthStates);
    };
    window.addEventListener('hero-health', heroHealthEventListener);

    const heroCoinEventListener = ({detail}) => {
      setHeroCoins(detail.heroCoins);
    };
    window.addEventListener('hero-coin', heroCoinEventListener);

    return () => {
      window.removeEventListener('new-dialog', dialogBoxEventListener);
      window.removeEventListener('menu-items', gameMenuEventListener);
      window.removeEventListener('hero-health', heroHealthEventListener);
      window.removeEventListener('hero-coin', heroCoinEventListener);
    };
  }, [setCharacterName, setMessages]);

  return (
    <div>
      <div className={classes.gameWrapper}>
        <div
          id="game-content"
          className={classes.gameContentWrapper}
        >
          {/* this is where the game canvas will be rendered */}
        </div>
        {heroHealthStates.length > 0 && (
          <HeroHealth
            gameSize={{
              width,
              height,
              multiplier,
            }}
            healthStates={heroHealthStates}
          />
        )}
        {heroCoins !== null && (
          <HeroCoin
            gameSize={{
              width,
              height,
              multiplier,
            }}
            heroCoins={heroCoins}
          />
        )}
        {messages.length > 0 && (
          <DialogBox
            onDone={handleMessageIsDone}
            characterName={npcNames[characterName] || characterName}
            messages={messages}
            promptId={characterIndex}
            gameSize={{
              width,
              height,
              multiplier,
            }}
          />
        )}
        {gameMenuItems.length > 0 && (
          <GameMenu
            items={gameMenuItems}
            gameSize={{
              width,
              height,
              multiplier,
            }}
            position={gameMenuPosition}
            onSelected={handleMenuItemSelected}
          />
        )}
      </div>
    </div>
  );
}

export default App;
