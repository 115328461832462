import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Contract, ethers} from "ethers";

// Images
import dialogBorderBox from './assets/images/dialog_borderbox.png';

// Components
import Message from './Message';
import {ABI} from "./abi";

const useStyles = makeStyles((theme) => ({
  dialogWindow: ({width, height, multiplier}) => {
    const messageBoxHeight = Math.ceil((height / 3.5) * multiplier);
    return {
      imageRendering: 'pixelated',
      fontFamily: '"Press Start 2P"',
      textTransform: 'uppercase',
      backgroundColor: '#e2b27e',
      border: 'solid',
      borderImage: `url("${dialogBorderBox}") 6 / ${6 * multiplier}px ${6 * multiplier}px ${6 * multiplier}px ${6 * multiplier}px stretch`,
      padding: `${8 * multiplier}px`,
      position: 'absolute',
      top: `${Math.ceil((height * multiplier) - (messageBoxHeight * 1.4 + messageBoxHeight * 0.1))}px`,
      width: `${Math.ceil(width * 0.8 * multiplier)}px`,
      left: '50%',
      transform: 'translate(-50%, 0%)',
      minHeight: `${messageBoxHeight}px`,
    };
  },
  dialogTitle: ({multiplier}) => ({
    fontSize: `${8 * multiplier}px`,
    marginBottom: `${6 * multiplier}px`,
    fontWeight: 'bold',
  }),
  dialogFooter: ({multiplier}) => ({
    fontSize: `${8 * multiplier}px`,
    cursor: 'pointer',
    textAlign: 'end',
    position: 'absolute',
    right: `${6 * multiplier}px`,
    bottom: `${6 * multiplier}px`,
  }),
}));

const DialogBox = (
  {
    messages,
    promptId,
    characterName,
    onDone,
    gameSize,
  }) => {
  const {
    width,
    height,
    multiplier,
  } = gameSize;


  const talkableNpcs = [
    "Galadriel",
    "Legolas",
    "Gandalf",
    "Celeborn"
  ]

  const [userMessage, setUserMessage] = useState("")
  const [isUserMessageFinished, setIsUserMessageFinished] = useState(false)
  const [txHash, setTxHash] = useState("")

  const [response, setResponse] = useState("")

  const [currentMessage, setCurrentMessage] = useState(0);
  const [messageEnded, setMessageEnded] = useState(false);
  const [forceShowFullMessage, setForceShowFullMessage] = useState(false);
  const classes = useStyles({
    width,
    height,
    multiplier,
  });

  const handleClick = useCallback(() => {
    if (response) {
      setCurrentMessage(0);
      onDone();
      return;
    }
    if (!userMessage)
      setIsUserMessageFinished(true)
    if (currentMessage >= messages.length - 1) {
      setCurrentMessage(0);
      onDone();
    } else {
      onSubmit().then(() => {
        if (messageEnded) {
          setMessageEnded(false);
          setForceShowFullMessage(false);
          if (currentMessage < messages.length - 1) {
            setCurrentMessage(currentMessage + 1);
          } else {
            setCurrentMessage(0);
            onDone();
          }
        } else {
          setMessageEnded(true);
          setForceShowFullMessage(true);
        }
      })
    }

  }, [currentMessage, messageEnded, messages.length, onDone, userMessage]);

  useEffect(() => {
    const handleKeyPressed = (e) => {
      if (['Enter', 'Escape'].includes(e.code)) {
        handleClick();
      }
      if (["Space"].includes(e.code)) {
        setUserMessage(userMessage + " ")
      }
      if (["KeyW"].includes(e.code) && !e.shiftKey) {
        setUserMessage(userMessage + "w")
      }
      if (["KeyA"].includes(e.code) && !e.shiftKey) {
        setUserMessage(userMessage + "a")
      }
      if (["KeyS"].includes(e.code) && !e.shiftKey) {
        setUserMessage(userMessage + "s")
      }
      if (["KeyD"].includes(e.code) && !e.shiftKey) {
        setUserMessage(userMessage + "d")
      }
      if (["keyw"].includes(e.code)) {
        setUserMessage(userMessage + "w")
      }
      if (["keya"].includes(e.code)) {
        setUserMessage(userMessage + "A")
      }
      if (["keys"].includes(e.code)) {
        setUserMessage(userMessage + "S")
      }
      if (["keyd"].includes(e.code)) {
        setUserMessage(userMessage + "D")
      }
    };
    window.addEventListener('keydown', handleKeyPressed);

    return () => window.removeEventListener('keydown', handleKeyPressed);
  }, [handleClick]);

  const handleInputChange = (event) => {
    setUserMessage(event.target.value);
  };

  const onSubmit = async () => {
    if (!talkableNpcs.includes(characterName) || !userMessage) {
      setIsUserMessageFinished(false)
      return
    }
    console.log(userMessage)
    setIsUserMessageFinished(true)
    if (!window.ethereum) {
      setResponse("Connect wallet")
      console.error("Not connected!");
      return;
    }
    setResponse("Thinking...")

    try {
      // Request account access if needed
      await window.ethereum.request({method: 'eth_requestAccounts'});

      // Create a provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Get the signer
      const signer = provider.getSigner();

      // TODO: env variable?
      const contract = new Contract("0x19DCDa0c205607c996C849db081b3BfeaB53A08f" || "", ABI, signer)
      const tx = await contract.startChat(userMessage, ethers.BigNumber.from(promptId))
      const receipt = await tx.wait()
      setTxHash(receipt.transactionHash)
      const chatId = getChatId(receipt, contract)
      console.log("chatId")
      console.log(chatId)
      if (chatId !== undefined) {
        while (true) {
          // TODO: is 2 correct?
          const newMessages = await getNewMessages(contract, chatId, 2);
          if (newMessages) {
            console.log("newMessages")
            console.log(newMessages)
            const lastMessage = newMessages.at(-1)
            if (lastMessage) {
              if (lastMessage.role === "assistant") {
                setResponse(lastMessage.content)
                break
              }
            }
          }
          await new Promise(resolve => setTimeout(resolve, 500))
        }
      }
    } catch (error) {
      console.error('Transaction failed:', error);
      console.error("Error occurred, try again!");
    }
  }

  function getChatId(receipt, contract) {
    let chatId
    for (const log of receipt.logs) {
      try {
        const parsedLog = contract.interface.parseLog(log)
        if (parsedLog && parsedLog.name === "ChatCreated") {
          // Second event argument
          chatId = parsedLog.args[1].toNumber();
        }
      } catch (error) {
        // This log might not have been from your contract, or it might be an anonymous log
        console.log("Could not parse log:", log)
      }
    }
    return chatId;
  }

  async function getNewMessages(
    contract,
    chatId,
    currentMessagesCount,
  ) {
    const messages = await contract.getMessageHistory(chatId)

    const newMessages = []
    messages.forEach((message, i) => {
      if (i >= currentMessagesCount) {
        newMessages.push({
          role: messages[i].role,
          content: messages[i].content[0][1]
        })
      }
    })
    return newMessages;
  }

  return (
    <div className={classes.dialogWindow}>
      <div className={classes.dialogTitle}>
        {characterName}
      </div>
      <Message
        action={messages[0].action}
        message={messages[0].message}
        key={0}
        multiplier={multiplier}
        forceShowFullMessage={forceShowFullMessage}
        onMessageEnded={() => {
          setMessageEnded(true);
        }}
      />
      {talkableNpcs.includes(characterName) && <>
        <div
          className={classes.dialogTitle}
          style={{marginTop: "20px"}}
        >
          You
        </div>
        {isUserMessageFinished ?
          <div
            style={{marginBottom: "10px"}}
          >
            <span style={{paddingRight: "20px"}}>
            {userMessage}
            </span>
            {txHash && <a
              href={`https://explorer.galadriel.com/tx/${txHash}`}
              target="_blank"
            >
              {txHash.slice(0, 16)}...
            </a>
            }
          </div>
          :
          <input
            name="prompt"
            value={userMessage}
            onChange={handleInputChange}
            style={{marginBottom: "20px", height: "40px", fontSize: "20px"}}
          >
          </input>
        }
      </>}
      {response &&
        <>
          <div className={classes.dialogTitle}>
            {characterName}
          </div>
          <Message
            action={messages[0].action}
            message={response}
            key={0}
            multiplier={multiplier}
            forceShowFullMessage={forceShowFullMessage}
            onMessageEnded={() => {
              setMessageEnded(true);
            }}
          />
        </>
      }
      <div
        onClick={handleClick}
        className={classes.dialogFooter}
      >
        {(currentMessage === messages.length - 1 && messageEnded) ? 'Ok' : 'Next'}
      </div>
    </div>
  );
};

export default DialogBox;
