import {Scene} from 'phaser';
import {providers} from "ethers";
import {web3Modal} from "../../context/Web3";
import {ethers} from "ethers";

export default class MainMenuScene extends Scene {


  constructor() {
    super('MainMenuScene');
  }

  preload() {
    // TODO
  }

  create() {
    const {width: gameWidth, height: gameHeight} = this.cameras.main;

    this.add.image(gameWidth / 2, Math.ceil(gameHeight / 10), 'game_logo')
      .setScale(0.8)
      .setOrigin(0.5, 0)
      .setDepth(1);

    const scale = Math.max(Math.ceil(gameWidth / 480), Math.ceil(gameHeight / 216)) / 8;
    this.add.image(0, 0, 'main_menu_background')
      .setScale(scale)
      .setDepth(0)
      .setOrigin(0, 0);

    const customEvent = new CustomEvent('menu-items', {
      detail: {
        menuItems: ['start', 'faucet'],
        menuPosition: 'center',
      },
    });

    window.dispatchEvent(customEvent);

    const gameMenuSelectedEventListener = async ({detail}) => {
      switch (detail.selectedItem) {
        case 'start': {
          if (!window.ethereum) {
            alert("Use a browser with web3 wallet support!")
            return
          }
          web3Modal.clearCachedProvider();
          const web3Provider = await web3Modal.connect();
          const provider = new providers.Web3Provider(web3Provider);
          try {
            if ((await provider.getNetwork()).chainId !== 696969) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: ethers.utils.hexValue(696969)}],
              });
              // Reconnect after switching the network
              const newProvider = new ethers.providers.Web3Provider(web3Provider);
              const newNetwork = await newProvider.getNetwork();
              if (newNetwork.chainId !== 696969) {
                window.alert("Failed to switch to the desired network.");
                return;
              }
            }
          } catch (switchError) {
            if (switchError.code === 4902) {
              // The network is not added to the wallet, prompt the user to add it
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{
                    chainId: ethers.utils.hexValue(696969),
                    chainName: 'Galadriel',
                    nativeCurrency: {
                      name: 'Galadriel',
                      symbol: 'GAL',
                      decimals: 18
                    },
                    rpcUrls: ['https://devnet.galadriel.com'],
                    blockExplorerUrls: ['https://explorer.galadriel.com']
                  }]
                });
                // Reconnect after adding the network
                const newProvider = new ethers.providers.Web3Provider(web3Provider);
                const newNetwork = await newProvider.getNetwork();
                if (newNetwork.chainId !== 696969) {
                  window.alert("Failed to add and switch to the desired network.");
                  return;
                }
              } catch (addError) {
                window.alert("Failed to add the network.");
                return;
              }
            }

            return;
          }

          const signer = provider.getSigner();
          const acc = await signer.getAddress();
          console.log(acc);
          // connectButton.setText(acc);
          // connectButton.disable();

          web3Provider.on("disconnect", () => {
            window.location.reload();
          });

          web3Provider.on("accountsChanged", (accounts) => {
            if (accounts.length === 0) {
              // connectButton.setText("Connect");
              // connectButton.enable();
            } else {
              // connectButton.setText(accounts[0]);
            }
          });
          this.scene.start('GameScene', {
            heroStatus: {
              position: {x: 4, y: 3},
              previousPosition: {x: 4, y: 3},
              frame: 'hero_idle_down_01',
              facingDirection: 'down',
              health: 60,
              maxHealth: 60,
              coin: 0,
              canPush: false,
              haveSword: false,
            },
            mapKey: 'home_page_city_house_01',
          });
          break;
        }
        case
        'faucet'
        : {
          window.open("https://discord.gg/galadriel", '_blank').focus();
          break;
        }

        case
        'settings'
        : {
          break;
        }

        default: {
          break;
        }
      }

      window.removeEventListener(
        'menu-item-selected',
        gameMenuSelectedEventListener
      );
    };

    window.addEventListener(
      'menu-item-selected',
      gameMenuSelectedEventListener
    );
  }
}
